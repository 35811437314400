@font-face {
	font-family: 'Blanka';
	/* font-weight: 400; */
	font-style: normal;
	src: url('./fonts/Blanka-Regular.otf') format('opentype');
}

body {
	margin: 0;
	font-family: Blanka, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
		'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #18122b;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.genbays-title {
	font-family: 'Blanka';
	justify-items: 'center';
	font-size: 4rem !important;
	letter-spacing: 1rem;
}

#player-container {
	width: 80vw;
	position: absolute;
	top: 50%;
	left: 50%;
	-moz-transform: translateX(-50%) translateY(-50%);
	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
}

.plyr {
	height: 100vh;
	min-height: 100%;
}

.ytp-pause-overlay-container {
	display: none;
}

.main {
	margin: 0;
	padding: 0;
	display: flex;
	box-sizing: border-box;
	align-content: center;
	justify-content: center;
	height: 100vh;
	background-color: #18122b;
}

.ant-input.main-search {
	font-weight: 500;
	color: rgba(43, 34, 79, 1);
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-size: x-large;
	/* border: 0.5px; */
	width: 100%;
	height: 100%;
	padding: 10px 20px;
	background: #443c68;
	border-radius: 10px;
	max-width: 30rem;
	border-color: rgba(43, 34, 79, 1);
}

.ant-input:focus.main-search {
	outline: none;
	border-color: rgba(43, 34, 79, 1);
	box-shadow: 0px 9px 20px rgba(43, 34, 79, 0.8);
	-webkit-box-shadow: 0px 3px 10px rgba(43, 34, 79, 0.8);
}

.ant-input.header-search {
	height: fit-content;
	align-self: center;
	font-weight: 500;
	color: rgba(43, 34, 79, 1);
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-size: medium;
	/* border: 0; */
	padding: 10px 20px;
	background: #635985;
	/* border-radius: 10px; */
	max-width: 20rem;
	border-color: rgba(43, 34, 79, 1);
}

.header-button-space {
	height: fit-content;
	align-self: center;
}

.ant-input:focus.header-search {
	outline: none;
	border-color: rgba(43, 34, 79, 1);
	box-shadow: 0px 9px 20px rgba(43, 34, 79, 0.8);
	-webkit-box-shadow: 0px 3px 10px rgba(43, 34, 79, 0.8);
}

.ant-btn {
	border: 0;
	color: white;
	font-size: medium;
	height: fit-content;
	border-radius: 5px;
	background-color: rgba(43, 34, 79, 1);
	margin: 0 10px 0 10px;
}
.ant-btn:hover {
	outline: none;
	border: 0;
	font-size: medium;
	color: white !important;
	height: fit-content;
	box-shadow: 0px 1px 5px rgba(43, 34, 79, 0.8);
	background-color: rgba(43, 34, 79, 1);
}

.ant-layout {
	background: rgb(24, 18, 43);
}

.ant-layout.header-layout {
	border-bottom: 5px rgb(68, 60, 104) solid;
}

.ant-layout-header {
	background: rgb(24, 18, 43);
}

.ant-layout-content {
	display: block;
	width: 90%;
	margin: auto;
	padding-top: 2%;
}

/* .list-wrap {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	flex-direction: row;
} */

.card-wrapper {
	position: relative;
	display: flex;
}

.card-delete-button button {
	position: absolute;
	display: flex;
	z-index: 1;
	right: 0;
	top: 0;
	margin: 0;
	background-color: whitesmoke;
	border-radius: 50px;
	color: #18122b;
	padding: 0.12rem;
	align-items: center;
	justify-content: center;
}

.card {
	margin: 0.5rem;
	position: relative;
	border: 0;
}

.ant-card-body {
	background-color: rgba(43, 34, 79, 1);
	width: 320px;
	height: auto;

	/* box-shadow: 1px 6px 10px 0px rgba(51, 51, 51, 0.5); */
}

/* .card:hover > .ant-card-body {
	z-index: 5;
	height: 100px;
	width: 320px;
	visibility: visible;
} */

.ant-card-meta-title,
.ant-card-meta-description {
	color: white !important;
}

.ant-card.card-extra-no-padding .ant-card-extra {
	padding: 0;
	margin: 0;
	position: absolute;
	top: 0;
	right: 0;
}

p {
	position: absolute;
	bottom: 0;
	left: 0;
	color: white;
	backdrop-filter: blur(10px);
}

.logo {
	font-family: 'blanka';
	justify-items: 'center';
	font-size: 1.5rem !important;
	letter-spacing: 0.5rem;
}

/* .ant-layout-content .search-wrapper {
	margin-top: 2rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: right;
	flex-direction: row;
} */

.search-wrapper > .browse-search {
	height: fit-content;
	align-self: center;
	font-weight: 500;
	color: rgba(43, 34, 79, 1);
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-size: medium;
	/* border: 0; */
	padding: 10px 20px;
	background: #635985;
	/* border-radius: 10px; */
	max-width: 20rem;
	border-color: rgba(43, 34, 79, 1);
}

.browse-search:hover {
	box-shadow: 1px 2px;
}

.browse-search:focus {
	box-shadow: 1px 2px;
}

.ant-table {
	border-color: rgba(43, 34, 79, 1);
}

.ant-table-row {
	border-color: rgba(43, 34, 79, 1);
}

.ant-table-thead > tr > th.ant-table-cell,
.ant-table-tbody > tr.ant-table-row > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
	border-color: rgba(43, 34, 79, 1);
}

.ant-modal-content,
.ant-modal-header {
	border-radius: 20px 20px 0 0;
	background-color: rgba(43, 34, 79, 1) !important;
}

.ant-modal-title {
	color: white !important;
}

.editable-row .ant-form-item-explain {
	position: absolute;
	top: 100%;
	font-size: 12px;
}
